import { createStyles, UnstyledButton } from "@mantine/core"
import { Header } from "widgets/header"
import { Footer } from "widgets/footer"
import React, { useEffect } from "react"
import { BannerStore } from "../store"
import { observer } from "mobx-react-lite"
import { API_URL } from "../../shared/config"
import { Link } from "react-router-dom"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  rootWrapper: {
    boxSizing: "border-box",
    minWidth: 320,
    minHeight: "100vh",
  },

  wrapper: {
    display: "flex",
    boxSizing: "border-box",
  },

  main: {
    flex: 1,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    boxSizing: "border-box",
    width: "100vw",
  },

  banner: {
    width: "100%",
    maxWidth: 1440,
    margin: "0 auto",
  },

  bannerImg: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
}))

// ----------------------------------------------------------------------

export type Props = {
  children: React.ReactNode
};

export const MainLayout = observer(function MainLayout({ children }: Props) {
  const { classes } = useStyles()
  const { getActiveBanner, activeBanner } = BannerStore

  useEffect(() => {
    void getActiveBanner()
  }, [ getActiveBanner ])

  return (
    <section className={classes.rootWrapper}>
      {activeBanner && (
        <div className={classes.banner}>
          <UnstyledButton component={Link} to={activeBanner.url} target="_blank">
            <img
              alt="Баннер"
              className={classes.bannerImg}
              src={`${API_URL}/media/${activeBanner.image}`}
            />
          </UnstyledButton>
        </div>
      )}

      <Header/>
      <section className={classes.wrapper}>
        <main className={classes.main}>{children}</main>
      </section>
      <Footer/>
    </section>
  )
})
